$primaryColor: #092f52;
$whiteColor: #ffffff;
$secondaryColor: #fbc10e;
$whiteGray: #e1e5f3;
$thirdColor: #6a46fe;
$greenColor: #38c883;
$blackColor: #333333;
a {
    text-decoration: none;
}
//** splash **//
.page {
    height: 100%;;
    &.splash-screen {
        padding: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 -84px;
        background-image: url('../images/bgs.svg');
        .logo-holder {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;       
            padding-top: 48px;
            margin-bottom: 100px; 
            .main-logo {
                width: 140px;
            }    
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            background-image: url('../images/bgSplash.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
        }
    }
}
//** splash **//
//** header **//
.app-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    margin-bottom: 32px;
    .back-icon {
        margin-left: 16px;
        color: $primaryColor;
    }
    .header-shade {
        position: absolute;
        z-index: 0;
        right: 0;
        left: 0;
        top:-8px;
        height: 64px;
        width: 100%;
    }
    .main-logo {
        width: 64px;
        margin-left: 16px;
    }
    .burger-menu {
        width: 48px;
        height: 48px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .short-link {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: $primaryColor;
        border-bottom-right-radius: 25px;
        border-right: 2px solid $secondaryColor;
        a {
            display: inline-blockx;
            padding: 8px 16px;
            color: $whiteColor;
        }
    }
    .logoIlets {
        position: relative;
        z-index: 2;
        cursor: pointer;
    }
}
//** header **//

//** profile **//
.profile .MuiTabs-scroller {
    justify-content: center;
    display: flex;
}
.profile .MuiTabs-root .MuiTab-root {
    border: 1px solid #000;
    margin: 0 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}
.profile .MuiTabs-root .Mui-selected {
    background-color: #ffffff;    
}
//**MyCourses**//
.myCoursesTabs {
    .tabs {
        display: flex;
        margin-bottom: 32px;
        justify-content: center;
        border-bottom: 2px solid rgba($primaryColor , 0.2);
        .tabItem {
            display: flex;
            position: relative;
            align-items: center;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            border-bottom: none;
            height: 32px;
            width: 160px;
            justify-content: center;
            background-color: $whiteGray;
            border: 2px solid $whiteGray;
            cursor: pointer;
            &.active {
                border: 2px solid rgba($primaryColor , 0.2);
                background-color: $whiteColor;
                &::after {
                    content: '';
                    position: absolute;
                    height: 6px;
                    background-color: $whiteColor;
                    width: 100%;
                    left: 0;
                    bottom: -5px;
                    z-index: 2;
                }
            }
        }
    }
}
//**VideoPlayer**//
.videoPlayer {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .video-controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .pooster {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            z-index: 1;
            transition: 0.3s all;
            &.hide {
                opacity: 0;
                visibility: hidden;
            }
        }
        .hoverDetails {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0 , 0 , 0 , 0.2);
            z-index: 2;
            transition: 0.3s all;
            &.showOnHover {
                opacity: 0;
            }
            .buttonActionHolder {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50% , -50%);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.video-controls-progress {
    border-radius: 20px;
    height: 32px;
    display: flex;
    align-items: center;
    direction: ltr;
    background-color: #f6f6f6;
    padding: 0 6px;
    margin: 8px 0;
    .buttonPlayPouse , .buttonFullScreen{
        width: 20px !important;
        height: 20px !important;
        padding: 4px;
        border-width: 1px !important;
        border-color: #7f7f7f !important;
        margin-right: 12px;
        svg {
            left: 1px !important;
        }
    }
    .buttonFullScreen {
        margin-right: 4px;
    }
    .slider {
        height: 5px;
    }
    .progress-line {
        border-radius: 15px;
        overflow: hidden;
        background-color: rgba(0 , 0 , 0 , 0.12) !important;
        .progress-line-active {
            border-radius: 15px;
            background-color: $thirdColor;
        }
    }
    .video-duration {
        min-width: 30px;
        font-size: 12px;
        margin: 0 12px;
        color: #7f7f7f;
        display: flex;
        height: 14px;
    }
}
.buttonFullScreen {
    width: 32px !important;
    height: 32px !important;
}
.buttonPlayPouse, .buttonFullScreen {
    position: relative;
    left: 2px;
    width: 60px;
    height: 60px;
    background-color: $whiteColor !important;
    border: 3px solid rgba(0, 0, 0, 0.16) !important;
    border-radius: 50%;
    &.buttonPlay {
        svg {
            position: relative;
            left: 2px;
        }
    }
    svg {
        fill: $thirdColor;
        stroke: $thirdColor;
    }
}
/**cardsHolder**/
.card-tour {
    position: relative;
    padding-bottom: 110px;
    .information {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transform: scale(1.1);
        transition: 0.3s all;
        z-index: 11;
        &.active {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
        }
        .darklayer {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.37);
        }
        .inner {
            position: relative;
            background-color: #ffffff;
            z-index: 11;
            border-radius: 12px;
            width: 800px;
            max-width: calc(100% - 48px);
            margin: 24px;
            .heading {
                padding: 16px;
                p {
                    font-size: 20px;
                }
            }
            .content {
                font-family: initial;
                direction: ltr;
                padding: 16px;
                color: #7f7f7f;
                font-size: 16px;
                font-weight: 100;
                line-height: 2;
                overflow-y: auto;
                p {
                    color: #7f7f7f;
                    font-size: 16px;
                    font-weight: 100;
                    line-height: 2;
                }
            }
        }
    }
    .bottom-rate {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        overflow: hidden;
        background-color: $whiteColor;
        display: flex;
        justify-content: center;
        width: 400px;
        max-width: 100%;
        .loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.95);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s all;
            transform: scale(0.9);
            &.active {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
        }
        button {
            flex: 0 0 33.3333%;
            height: 56px;
            border-radius: 0;
            font-size: 20px;
            font-weight: bold;
            &:nth-child(2) {
                border-right: 1px solid rgba(0,0,0,0.1);
                border-left: 1px solid rgba(0,0,0,0.1);
            }
            &.hard {
                color: #e63e3e;
            }
            &.medium {
                color: #6a46fe;
            }
            &.easy {
                color: #38c883;
            }
        }
    }
}
.cards-holder{
    margin-top: 30px !important;
    padding: 2px;
    border-radius: 15px;
    margin-top: 24px;
    position: relative;
    min-height: 400px;
    .inner-card{
        height: 100%;
        border-radius: 10px;
        border: solid 2px $primaryColor;
        border-top: none;
        overflow: hidden;
        position: relative;
        .heading{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            background-color: $primaryColor;     
            color:#fff;   
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }
    .flip-card-back {
        border: 5px solid $thirdColor;
        border-radius: 15px;
        .word-shapes {
            height: 180px;
            .content {
                padding-top: 48px;
                font-size: 12px;
                overflow-y: auto;
                height: 100%;
            }
        }
    }
    .flip-card-front {
        border: 5px solid #3e9adb;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        .word {
            font-size: 30px;
            color: #333333;
            font-weight: 300;
        }
        .intonation {
            font-size: 24px;
            color: #7f7f7f;
            font-weight: 100;
        }
    }
    .shaps-holder {
        padding: 8px;
    }
    .bottom-card-detail {
        margin: 32px 0;
        position: relative;
        z-index: 2;
        button {
            margin: 0 8px;
            background-color: rgba(226 ,230 ,243 ,0.5);
        }
        .info-button {
            padding: 12px 16px;
            border-radius: 25px;
            background-image: linear-gradient(to left, #6a46fe, #35237f);
            .MuiTypography-root {
                margin-left: 8px;
                font-size: 14px;
                color: $whiteColor;
            }
        }
    }
    .flip-card-button {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        position: absolute;
        bottom: 0;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        position: absolute;
        bottom: -28px;
        background-color: #fff !important;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }
}
.flip-card {
    background-color: transparent;
    width: 100%;
    perspective: 1000px;
    height: 500px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flip-card.rotate-180 .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    height: 500px;
  }

  .flip-card-back {
    height: 500px;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  .navigation-conatiner {
      .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        margin-left: -24px;
        margin-right: -24px;
        svg {
            color: #b8b8b8;
        }
        .nums {
            display: flex;
            > * {
                margin: 0 8px;
                font-weight: bold;
                font-size: 30px;
                color: #7f7f7f;
            }
            .current , .divider {
                color: #333333;
            }
        }
      }
  }
  //**Exam**//
  .exam-questions {
      margin: 0 24px;
  }

  .toast {
      button:disabled {
        background-color: #b8b8b8 !important;
        color: $whiteColor;
      }
      &.finalExam {
          .spanFinalExam {
              font-size: 28px;
              font-weight: 400;
              color: #7f7f7f;
          }
      }
  }

  .dialogCloseExam {
    .MuiPaper-root {
        width: 335px;
        max-width: 90%;
        border-radius: 12px;
        #alert-dialog-slide-title {
            h2 {
                font-size: 18px;
                color: $primaryColor;
            }
        }
        p {
            font-weight: 100;
            width: 100%;
            left: 32px !important;
            bottom: 12px !important;
        }
        .MuiDialogActions-root {
            padding: 16px 20px;
            justify-content: space-between;
            button {
                flex: 0 0 50%;
                height: 40px;
                border-radius: 30px;
                font-size: 20px;
                font-weight: 100;
                padding: 0;
                line-height: 0;
                color: $blackColor;
                &.continue {
                    background-color: $greenColor;
                    color: $whiteColor;
                    font-weight: bold;
                }
            }
        }
    }
    &.dialogExamFinal.fullBtn {
        .MuiDialogActions-root {
            button {
                flex: 0 0 100% !important;
            }
        }
    }
}

.britishPron, .americanPron {
    position: relative !important;
    background-size: cover;
    overflow: hidden;
    .MuiIconButton-label {
        position: relative;
        z-index: 2;
        color: $whiteColor;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.52);
        border-radius: 50%;
    }
}

.britishPron {
    background-image: url('../images/tn_us-flag.gif');
}
.americanPron {
    background-image: url('../images/tn_uk-flag.gif');
}
.page {
    p {
        font-weight: 100;
    }
}
.derivative-two {
    .heading {
        font-size: 12px;
    }
    .content {
        padding-top: 38px !important;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
}
// MediaQueries
@media(max-width: 400px) {
    .splash-screen {
        .logo-holder {
            margin-bottom: 0 !important;
        }
    }
    .MuiButton-label {
        font-size: 14px;
    }
    .toast {
        button {
            margin-right: 8px;
        }
    }
    .dialogCloseExam {
        .MuiPaper-root p {
            bottom: 0 !important;
            left: 16px !important;
        }
        .MuiDialogActions-root {
            flex-wrap: wrap;
        }
        button {
            flex: 0 0 100% !important;
            font-size: 16px;
            margin-right: 0 !important;
            &:first-child {
                margin-bottom: 8px !important;
            }
        }
    }
    .progress-state-holder {
        > .MuiBox-root {
            width: 20px !important;
        }
        .progress-state.type-vertical {
            width: 20px !important;
        }
    }
    .card-tour  {
        padding-bottom: 0 !important;
    }
    .navigation-conatiner .navigation {
        margin-top: 0;
    }
    .cards-holder {
        margin-top: 0 !important;
        .bottom-card-detail {
            margin-top:8px;
            margin-bottom: 8px;
            button {
                padding: 4px;
            }
            .info-button {
                padding: 4px 8px !important;
            }
        }
        .flip-card-front, .flip-card-back {
            height: 340px;
        }
    }
    .word-shapes {
        height: 135px !important;
    }
    .app-header {
        margin-bottom: 0;
        padding-right: 0;
        padding-bottom: 0;
        .back-icon {
            margin-left: 0;
        }
    }
}
