//** home **//
$buttonSubmitColors: #fd98aa;
$hover_buy_color: #c73e56;
.app-header {
    .menuholder{
        position: fixed;
        width: 400px;
        max-width: 80%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        height: 100%;
        right: -800px;
        z-index: 11;
        bottom: 0;
        transition: all .3s;
        opacity: 0;
        visibility: hidden;
        overflow-y: auto;
        nav {
            overflow-y: auto;
        }
        &.active {
            opacity: 1;
            visibility: visible;
            right: 0;
        }
        .header-menu{
            display: flex;
            align-items: center;
            padding: 16px;
            background-color: #f6f6f6;
            .your-information{
                margin-right: 8px;
            }
            .close-menu{
                position: absolute;
                left: 24px;
                border: none;
                background: none;
                color: #969696;
                outline: none;
                cursor: pointer;
            }
        }
        .footer-menu{
            padding: 16px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0;
            width: calc(100% - 48px);
            background-color: #fff;
            box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.12);
        }
        @media (max-width:374px) {
            .footer-menu {
                position: relative;
            }
        }
        .social-media{
            display: flex;
                .instagram{
                    margin-right: 24px;
                }
                margin-right: 16px;
                a{
                    color:#333333;
                    display: flex;
                }
        }
        .MuiListItem-gutters{
            padding-left: 24px;
            padding-right: 24px;
            a {
                display: block;
                width: 100%;
            }
        }
    }
}
.page {
    &.home {
        .categories-holder {
            height: 64px;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
    .Tags-holder {
        margin-bottom: 16px;
        .Tags{
            display: flex;
            padding: 0 24px;
            overflow-x: auto;
            .category-item{
                background-color: #1a4874;
                list-style: none;
                padding:0px 4px;
                margin-left: 8px;
                border-radius: 5px;
                white-space: nowrap;
                &.active{
                    background-color: $buttonSubmitColors;
                }
                a{
                    color:#fff;
                    text-decoration: none;
                    font-size: 13px;
                }
            }
        }
    }
    
    .courses{
        display: flex;
        flex-wrap: wrap;
        .course {
            .inner{
                border-radius: 20px;
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
                border: solid 1px #f5f5f7;
                background-color: #ffffff;
                overflow: hidden;
                .course-details {
                    padding: 12px;
                }
                .image-course{
                    height: 160px;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            &.even {
                .image-course {
                    height: 160px;
                }
            }
        }
    }
    .darklayer{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 11;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s all;
        &.active{
            opacity: 1;
            visibility: visible;
        }
    }
    .heading-holder {
        h2 {
            max-width: 70%;
            white-space: pre-wrap;
        }
    }
    &.lessonl{
        position: relative;
        .heading-holder{
            display:flex;
            align-items: center;
            position: relative;
        }
        .footer-holder{
            width: 100%;
            bottom: 70px;
            height: 70px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            .review{
                width: 85%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #38c883;
                border-radius: 30px;
                color:#fff;
                padding:  0 8px;
                font-size: 20px;
                font-weight: bold;
                .card-amount{
                    width: 60px;
                    height: 30px;
                    border-radius: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    background-color: #4ca06a;
                    font-weight: normal;
                }
            }
        }
        @media (max-height: 940px) {
            .footer-holder {
                bottom: 0;
                width: 100%;
            }
        }
    }
    &.single-lesson{
        padding-bottom: 196px;
        .single-lesson-content{
            margin: 16px 0;
        }
    }
}
.progress-line{
    width: 100%;
    height: 5px;
    background-color: rgba(127 ,127 ,127 ,0.2);
    .progress-line-active {
        background-color: #61c984;
        height: 5px;
        transition: 0.3s all;;
    }
}
.progress-numebr{
    width: 60px;
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: #e6e9ed;
    position: absolute;
    left: 0;
    font-size: 28px;
    .amount{
        color:#38c883;
        position: relative;
        top: 3px;
    }
}
.lesson-item-holder{
    .lesson-items{
        padding-right:0;
        .lesson-item{
            list-style: none;
            display: flex;
            position: relative;
            align-items: center;
            margin-bottom: 30px;
            .lesson-session-index{}
            .lesson-session-detail{
                .lesson-session-title{

                }
                .lesson-session-card-count{
                    
                }
            }
            .status {
                position: absolute;
                left: 0;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #092f52;
                color: #fff;
                border-radius: 15px;

                &.passed-active {
                    background-color: #38c883;
                }

                &.locked {
                    background-color: #e1e5f3;
                }
                &.playing{
                    background-color: #fbc108;
                }
            }
            
        }
    }
}

.heading-holder{
    display:flex;
    align-items: center;
    position: relative;
    .course-detail{
    }
}
.footer-holder{
    height: 70px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    &.cards, &.exam {
        justify-content: space-between;
    }
    .review{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #38c883;
        border-radius: 30px;
        color:#fff;
        padding:  0 8px 0 6px;
        font-size: 20px;
        font-weight: bold;
        .card-amount{
            width: 60px;
            height: 30px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background-color: #4ca06a;
            font-weight: normal;
        }
    }
}
.pb-60{
    padding-bottom: 60px;
}
.mb-0{
    margin-bottom: 0 !important;
}
.mb-16{
    margin-bottom: 16px;
}
.mt-8{
    margin-top: 8px;
}
.mb-8{
    margin-bottom: 8px;
}
.search-holder{
    height: 35px;
    display: flex;
    align-items: center;
    background-color: #e6e9ed;
    color: #7f7f7f;
    border-radius: 50px;
    padding: 8px;
    margin-bottom: 30px;;
    form{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input{
            background-color: transparent;
            border: none;
            outline: none;
            font-family:iransans ;
            padding-right: 16px;
            width: calc(100% - 56px);
        }
        button{
            background-color: transparent;
            border: none;
            outline: none;
            width: 40px;
            height: 40px;
            color: #7f7f7f;
        }
    }
}
.card-holder{
    .cards{
        padding-right: 0;
        .card{
            display: flex;
            justify-content:space-between;
            align-items: center;
            background-color: #f5f2ff;
            height: 80px;
            padding:  0  24px;
            border-radius: 10px;
            border-right: 4px solid #6a46fe;
            margin-bottom: 30px;
            .right-card{
                .card-heading{
                    display: flex;
                    align-items: center;
                    .icon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 16px;
                        height: 16px;
                        border :1px solid #333; 
                        border-radius: 5px;
                        margin-left: 8px;
                        color: #333;
                    }
                }
                .detail{
    
                }
            }
            .left-card{
                .downloader{
                    width: 40px;
                    height: 40px;
                    background-color: #fff;
                    border-radius: 5px;
                    color:#6a46fe;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                }
            }
            &.type-course {
                .downloader {
                    border-radius: 50%;
                }
            }
        }
    }
}
.card-header{
    background-color: #f6f6f6;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  0 24px;
    .right-card-head{

    }
    .left-card-head{
        .close{
            background-color: transparent;
            border: none;
            outline: none;
            color: #7f7f7f;
            display: flex;
            align-items: center;
            cursor: pointer ;
        }
    }
}
.MuiFormLabel-root{
    background-color: #fff;
    padding: 0 8px !important;
    font-family: iransans !important;
}
.MuiInputBase-input{
    font-family: iransans !important;
   
}
.MuiFormControl-root {
    margin-bottom: 30px !important;
    width: 100%;
}
.register-holder{
    margin-top: 30px;
    .MuiFormLabel-root{
        background-color: #fff;
        padding: 0 8px !important;
        font-family: iransans !important;
    }
    .MuiInputBase-input{
        font-family: iransans !important;
        border-radius: 25px;
       
    }
    .MuiFormControl-root {
        margin-bottom: 30px !important;
        width: 100%;
    }
    .MuiOutlinedInput-root{
        border-radius: 25px;
    }
    .MuiButtonBase-root{
        padding: 16px;
        background-color: #4CAF50;
        color:#fff;
        width: 100%;
        border-radius: 25px;
        &:hover{
            background-color: #4CAF50;  
        }
    }
}
.add-to-card{
    .course-image{
        width: 100%;
        height: 190px;
        background-size: cover;
        background-position: center;
        border-radius: 20px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
        overflow: hidden;
        }
        .footer-holder{
            display: flex;
            align-items: center;
            justify-content:space-between ;
            .righ-footer{

            }   
            .left-footer{
                &.buy-btn{
                    border: 2px solid $buttonSubmitColors;
                    border-radius: 25px;
                    padding: 3px;  
                    transition: 0.3s border-color;
                    &:hover {
                        border-color: $hover_buy_color;
                    }
                }
            } 
        }

}

// ButtonSubmit
.button-submit {
    color: #fff !important;
    height: 40px !important;
    padding: 0 28px !important;
    font-size: 18px !important;
    width: auto  !important;
    white-space: nowrap !important;
    border-radius: 30px !important;
    background-color: $buttonSubmitColors !important;
    box-shadow: none !important;
}

//** home **//