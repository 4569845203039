@font-face {    
    font-family: 'iransans';
    src: url('../fonts/IRANSansWeb(FaNum)_UltraLight.eot');
    src: url('../fonts/IRANSansWeb(FaNum)_UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'),
        url('../fonts/IRANSansWeb(FaNum)_UltraLight.woff') format('woff'),
        url('../fonts/IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;    
}
@font-face {    
    font-family: 'iransans';
    src: url('../fonts/IRANSansWeb(FaNum)_Light.eot');
    src: url('../fonts/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IRANSansWeb(FaNum)_Light.woff2') format('woff2'),
        url('../fonts/IRANSansWeb(FaNum)_Light.woff') format('woff'),
        url('../fonts/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;    
}
@font-face {    
    font-family: 'iransans';
    src: url('../fonts/IRANSansWeb(FaNum)_Medium.eot');
    src: url('../fonts/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),
        url('../fonts/IRANSansWeb(FaNum)_Medium.woff') format('woff'),
        url('../fonts/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;    
}
@font-face {    
    font-family: 'iransans';
    src: url('../fonts/IRANSansWeb(FaNum)_Bold.eot');
    src: url('../fonts/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),
        url('../fonts/IRANSansWeb(FaNum)_Bold.woff') format('woff'),
        url('../fonts/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;    
}
$direction_rtl: rtl;
$direction_ltr: ltr;
body {
    font-family: iransans;
    margin:0;
    direction: $direction_rtl;
}
.MuiFormHelperText-root, #notistack-snackbar {
    font-family: iransans !important; 
}
.main-logo {
    height: auto;
}
.MuiTypography-root, .MuiButton-root, .MuiTab-wrapper {
    font-family: iransans !important;
}
.MuiButton-endIcon {
    margin-left: -4px !important;
    margin-right: 8px !important;
}
.MuiButton-startIcon {
    margin-right: -4px !important;
    margin-left: 8px !important;
}
.ScrollbarsCustom {
    .ScrollbarsCustom-Track {
        opacity: 0 !important;
        transition: 0.3s all;      
    }
    &:hover {
        .ScrollbarsCustom-Track {
            opacity: 1 !important;           
        }
       
    }  
}
